import React, { useEffect, useState, useContext, useMemo } from 'react';
import axios from 'axios';
import Loader from '../GeneralScreens/Loader';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { AuthContext } from "../../Context/AuthContext";
import '../../Css/EditStory.css'
import JoditEditor from 'jodit-react';

const EditStory = () => {
    const { config } = useContext(AuthContext)
    const slug = useParams().slug
    const [loading, setLoading] = useState(true)
    const [story, setStory] = useState({}) // eslint-disable-line
    const [image, setImage] = useState('')
    const [previousImage, setPreviousImage] = useState('')
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')
    const navigate = useNavigate()

    const editorConfig = useMemo(
        () => ({
            readonly: false
        }),
        []
    );

    useEffect(() => {

        const getStoryInfo = async () => {
            setLoading(true)
            try {
                const { data } = await axios.get(`https://stage.apis.pococare.com/blog/story/editStory/${slug}`, config)
                setStory(data.data)
                setTitle(data.data.title)
                setContent(data.data.content)
                setImage(data.data.image)
                setPreviousImage(data.data.image)
                setLoading(false)
            }
            catch (error) {
                navigate("/")
            }
        }
        getStoryInfo()
    }, []) // eslint-disable-line

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formdata = {
            title,
            content,
            image
        }

        try {
            const { data } = await axios.put(`https://stage.apis.pococare.com/blog/story/${slug}/edit`, formdata, config) // eslint-disable-line

            setSuccess('Edit Story successfully ')

            setTimeout(() => {
                navigate('/')
            }, 2500)

        }
        catch (error) {
            setTimeout(() => {
                setError('')
            }, 4500)
            setError(error.response.data.error)
        }
    }



    return (
        <>
            {
                loading ? <Loader /> : (
                    <div className="Inclusive-editStory-page ">
                        <form onSubmit={handleSubmit} className="editStory-form">

                            {error && <div className="error_msg">{error}</div>}
                            {success && <div className="success_msg">
                                <span>
                                    {success}
                                </span>
                                <Link to="/">Go home</Link>
                            </div>}

                            <input
                                type="text"
                                required
                                id="title"
                                placeholder="Title"
                                onChange={(e) => setTitle(e.target.value)}
                                value={title}
                            />

                            <JoditEditor
                                value={content}
                                config={editorConfig}
                                tabIndex={1}
                                onChange={newContent => setContent(newContent)}
                            />

                            <div class="currentlyImage">
                                <div class="absolute">
                                    Currently Image
                                </div>
                                <img src={`${previousImage}`} alt="storyImage" />
                            </div>
                            {/* <div class="StoryImageField"> */}
                            <input
                                style={{ marginTop: "10px" }}
                                name="image"
                                type="text"
                                placeholder='Header Image URL'
                                onChange={(e) => {
                                    setImage(e.target.value)
                                }}
                            />
                            {/* </div> */}

                            <button type='submit' className='editStory-btn'
                            >Edit Story </button>
                        </form>

                    </div>
                )
            }
        </>
    )
}

export default EditStory;
