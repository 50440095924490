import React from 'react'

export const About = () => {
    return (
        <div style={{width: "90%", margin: "auto", marginTop: "60px"}}>
                <h1 class="entry-title">Who We Are?</h1>
            <div class="entry-content">
                <p class="has-medium-font-size"><strong>Redefining Medical Emergency Response</strong></p>
                <p>Pococare, which stands for &ldquo;Care with Positive Conversations,&rdquo; is a technology-driven healthcare organization. Our journey is driven by the firm belief that timely medical attention can save lives.</p>
                <p class="has-medium-font-size"><strong>Our Mission</strong></p>
                <p>Our mission is to reduce the current 90% gap in timely medical attention to at least 50% within the next 3 years.</p>
                <p class="has-medium-font-size"><strong>The Problem We are Solving</strong></p>
                <p>In India today, a staggering 90% of people don&rsquo;t receive the timely medical attention they need during emergencies. This results in devastating consequences, from loss of lives to financial burdens that cripple families.</p>
                <p class="has-medium-font-size"><strong>Our Approach</strong></p>
                <p>Our approach centers around seamless coordination between patients, healthcare providers, and emergency services. We have harnessed technology to create a lifeline during emergencies, ensuring that help arrives when it is needed most.</p>
                <p class="has-medium-font-size"><strong>Key Features</strong></p>
                <ul>
                    <li><strong>QR Code and Call Accessibility</strong>: Our services are easily accessible through a QR code or a simple phone call.</li>
                    <li><strong>Central Coordination</strong>: We serve as the central point of contact, coordinating with multiple stakeholders for a unified response.</li>
                    <li><strong>Swift Ambulance Response</strong>: Our tech-based solution ensures the quickest ambulance response.</li>
                    <li><strong>Automatic Notifications</strong>: We automatically notify emergency contacts.</li>
                    <li><strong>Doctor&rsquo;s Guidance</strong>: Our platform connects patients with a doctor for necessary guidance.</li>
                </ul>
                <p class="has-medium-font-size"><strong>Geographic Coverage</strong></p>
                <p>Pococare is headquartered in Bangalore and provides services in over 700 towns and cities across India.</p>
                <figure class="wp-block-image size-full is-resized">
                    <img  style={{width: "100%", height: "auto"}} src="https://i.postimg.cc/d0zFJTGm/Untitled-design-15.png 768w" alt="" width="705" height="591" /></figure>
                <p class="has-medium-font-size"><strong>Saving Lives and Ensuring Safety</strong></p>
                <p>Ultimately, our goal is simple: to save lives and ensure the safety of your loved ones when medical emergencies strike.</p>
                <p><strong>Every day, we are working tirelessly to ensure that no life is lost due to a lack of medical attention. Together, we can create a safer and more prepared India.</strong></p>
            </div>
        </div>
    )
}
