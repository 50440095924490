import React, { useRef, useContext, useMemo } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import JoditEditor from "jodit-react";
import { AuthContext } from "../../Context/AuthContext";
// import { AiOutlineUpload } from 'react-icons/ai'
import { FiArrowLeft } from 'react-icons/fi'
import '../../Css/AddStory.css'

const AddStory = () => {
    const { config } = useContext(AuthContext)
    const imageEl = useRef(null)
    const editorEl = useRef(null)
    const [image, setImage] = useState('')
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')

    const clearInputs = () => {
        setTitle('')
        setContent('')
        setImage('')
        editorEl.current.editor.setData('')
        imageEl.current.value = ""
    }

    const editorConfig = useMemo(
        () => ({
            readonly: false
        }),
        []
    );

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formdata = {
            title,
            content,
            image
        }

        try {
            const { data } = await axios.post("https://stage.apis.pococare.com/blog/story/addstory", formdata, config) // eslint-disable-line
            setSuccess('Add story successfully ')

            clearInputs()
            setTimeout(() => {
                setSuccess('')
            }, 7000)

        }
        catch (error) {
            setTimeout(() => {
                setError('')

            }, 7000)
            setError(error.response.data.error)

        }

    }
    return (

        <div className="Inclusive-addStory-page ">
            <Link to={'/'} >
                <FiArrowLeft />
            </Link>
            <form onSubmit={handleSubmit} className="addStory-form">

                {error && <div className="error_msg">{error}</div>}
                {success && <div className="success_msg">
                    <span>
                        {success}
                    </span>
                    <Link to="/">Go home</Link>
                </div>}

                <input
                    type="text"
                    required
                    id="title"
                    placeholder="Title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                />

                <JoditEditor
                    value={content}
                    config={editorConfig}
                    tabIndex={1}
                    onChange={newContent => setContent(newContent)} 
                />
                {/* <div class="StoryImageField"> */}
                <input
                    style={{marginTop: "10px"}}
                        name="image"
                        type="text"
                        placeholder='Header Image URL'
                        onChange={(e) => {
                            setImage(e.target.value)
                    }}
                    />
                {/* </div> */}
                <button type='submit' disabled={image ? false : true} className={image ? 'addStory-btn' : 'dis-btn'}
                >Publish </button>
            </form>

        </div>

    )
}

export default AddStory


